export const environment = {
  production: true,
  apiUrl: 'https://api-read-dev.nvxportal.com/api',
  eventSourceUrl: 'https://api-dev.nvxportal.com',
  appUrl: 'https://dev-app.nvx.ai',
  msalConfig: {
    apiScopes: ['openid', 'https://neelevatcustomer.onmicrosoft.com/agent-portal-api/read.all'],
    clientId: 'a79bbb95-63d9-4d57-a32b-919292ad294a',
    domain: 'login.lspportal.com',
    tenantId: '010952f3-67da-4af2-94e3-e000fd08b328',
    policies: {
      signUpSignIn: 'B2C_1_signin_admin',
      resetPassword: 'b2c_1_reset_custom',
      editProfile: 'b2c_1_edit_profile',
    },
  },
};
